import logo from './WhatsApp_Bild_2024-06-18_um_22.47.06_29c1b63f-removebg-preview(1)-min.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          printf("<code>für Förtnite</code>")
        </p>
      </header>
    </div>
  );
}

export default App;
